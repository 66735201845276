<template>
  <div class="container-full hot-goods">
    <div class="container" >
      <hot-goods-title />
      <div class="goods-content" v-if="goods.length">
        <div class="goods-content-right" >
	  <div class="card-list" v-for="(item, index) in goods" :key="index">
            <hot-goods-card :goodsItem="item" />
          </div>
        </div>
      </div>
       <div v-else> <img src="../../../assets/images/empty.png" alt="" width="100%"> </div>
    </div>
  </div>
</template>

<script>
import HotGoodsTitle from 'views/index/childComps/HotGoodsTitle'
import HotGoodsCard from 'views/index/childComps/HotGoodsCard'
import { getIndexGoods } from 'network/api'
export default {
  components: {
    HotGoodsTitle,
    HotGoodsCard
  },
  data() {
    return {
      goods: []
    }
  },
  created() {
    const key = localStorage.getItem('code')
    getIndexGoods({ key }).then((res) => {
      if (res.code !== 1) {
        return this.$message.warning(res.msg)
      }
      if (res.code === 1 && res.data.length !== 0) {
        this.goods = res.data.splice(0, 10)
      }
    })
  },
  methods: {
    mouseout(e) {
      this.active = ''
      // console.log(e)
    },
    mouseove(e) {
      this.active = e
    },
    toMall() {
      this.$router.push('/goods')
    }
  },
  mounted() {
    // 获取可视区域宽度
    const width = document.body.scrollWidth
    const app = document.querySelector('.hot-goods')
    // console.log(app.style)
    app.style.width = width + 'px'
  }
}
</script>

<style scoped src="styles/views/index/hotgoods.css">
</style>
