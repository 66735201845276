<template>
  <div class="backtop">
    <!-- <div class="kefu" @mouseover="mouseOver" @mouseleave="mouseLeave">
      <div class="icon"><i class="el-icon-service"></i></div>
      <span>客服</span>
      <div v-show="showKefu" @mouseover="mouseOverKefu" @mouseleave="mouseLeaveKefu" class="kefu-side" @click="onlineService">在线客服</div>
    </div> -->
    <div class="wechat">
      <div class="icon"><i class="el-icon-chat-dot-round"></i></div>
      <span>微信</span>
      <div class="wechat-side" v-if="$store.getters.getSiteInfo">
        <img v-show="$store.getters.getSiteInfo" :src="$store.getters.getSiteInfo.wechat_service">
      </div>
    </div>
    <div class="top" @click="backTop">
      <div class="icon"><i class="el-icon-upload2"></i></div>
      <span>顶部</span>
    </div>
    <div class="mobile" @click="yidong">
      <div class="icon"><i class="el-icon-mobile-phone"></i></div>
      <span >手机版</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showKefu: false,
      isMouseOverKefu: false,
      wechat_service: ''
    }
  },
  methods: {
    backTop () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    onlineService () {
      window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
    },
    mouseOver () {
      if (this.showKefu !== null) {
        this.showKefu = true
      }
    },
    mouseLeave () {
      const that = this
      setTimeout(function () {
        if (!that.isMouseOverKefu) {
          that.showKefu = false
        }
      }, 300)
    },
    mouseOverKefu () {
      this.isMouseOverKefu = true
      this.showKefu = true
    },
    mouseLeaveKefu () {
      this.isMouseOverKefu = false
      this.showKefu = false
    },
    yidong () {
      window.open(this.$store.getters.getSiteInfo.mobile_url, '_blank')
    }
  }
}
</script>

<style scoped src="styles/components/backtop.css">
</style>
